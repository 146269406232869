import React, { useState, useEffect, useMemo } from 'react';
import {
  DatePicker,
  Row,
  Col,
  Tooltip,
  Card,
  TreeSelect,
  Button,
  Collapse,
  Select,
} from 'antd';
import { getUsersTypeApi } from '../../api/user';
import { getAccessTokenApi } from '../../api/auth';
import ListTurns from '../../components/Ambulatory/ListTurns';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import Spin from '../../components/Spin';
import locale from 'antd/lib/date-picker/locale/es_ES';
import 'dayjs/locale/es';
import { getTurnPortalApi } from '../../api/portal';
import { onPatientTurnsRead, onPrimaryDataRead } from '../../actions';
import { InfoCircleOutlined, WhatsAppOutlined } from '@ant-design/icons';
import './Turns.scss';
import TurnDetail from '../../components/TurnDetail/TurnDetail';
import LogoNombre from '../../assets/img/a1-8.png';
import { days, feriados } from '../../utils/constants';
import { getServicesApi } from '../../api/service';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export default function Turns(props) {
  dayjs.locale('es');

  const {
    tenant,
    organization,
    studies,
    organizationPhone,
    tenantId,
    portalActive,
    tenantLogo,
    tenantTimezone,
  } = useSelector((store) => store.tenantData);
  const user = useSelector((store) => store.userData.user);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [slots, setSlots] = useState();
  const [date, setDate] = useState(
    dayjs().tz(tenantTimezone).format('YYYY-MM-DDTHH:mm:ss')
  );
  const [turn, setTurn] = useState([]);
  const [turnUser, setTurnUser] = useState(null);
  const [reload, setReload] = useState(false);
  const [size, setSize] = useState();
  const [turnType, setTurnType] = useState(null);
  const [turnDetail, setTurnDetail] = useState(false);
  const [data, setData] = useState();
  const [configSchedule, setConfigSchedule] = useState();
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDataTurn = async () => {
      setLoading(true); // Comienza el cargado
      try {
        // Obtener los usuarios
        const userResponse = await getUsersTypeApi(tenantId);
        setUsers(userResponse.users);

        // Si no es tipo 'Medico', se obtiene información primaria
        if (turnType !== 'Medico') {
          dispatch(onPrimaryDataRead(window.location.pathname.split('/')[1]));
        }

        // Obtener servicios
        const serviceResponse = await getServicesApi(tenantId);
        setServicesData(serviceResponse?.services);

        // Si el usuario tiene un ID, cargar turnos
        if (user?._id) {
          dispatch(onPatientTurnsRead(user._id));
        }

        // Solo proceder si `turnUser` está definido
        if (turnUser) {
          let dataSizeSlot;

          // Determinar el tamaño del slot dependiendo del tipo de turno
          if (turnType !== 'medico') {
            dataSizeSlot = studies.find(
              (studie) => studie.id === turnUser
            )?.defaultSizeSlot;
          } else {
            dataSizeSlot = users.find(
              (user) => user._id === turnUser
            )?.defaultSizeSlot;
          }

          // Obtener datos de los turnos
          const turnResponse = await getTurnPortalApi(turnUser, date, tenantId);
          setTurn(turnResponse?.turns);

          // Generar los horarios basados en el turno
          generateSlots(
            turnResponse?.turns?.shifts.length > 0
              ? turnResponse?.turns.sizeSlot
              : dataSizeSlot,
            turnResponse?.turns
          );
        }
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false); // Termina el cargado, independientemente de si hubo error o no
      }
    };

    getDataTurn(); // Llamar a la función asíncrona para obtener los datos
  }, [date, turnUser, reload]);

  const disabledDate = (current) => {
    if (!current) return false;

    const isPastOrFuture =
      current.isBefore(dayjs().startOf('day')) ||
      current.diff(dayjs(), 'days') >=
        (data?.scheduleConfig?.quantityDaysPortal || 15);

    // Convertir `current` al mismo formato para comparar
    const formattedDate = current
      .startOf('day')
      .tz(tenantTimezone)
      .toISOString();

    // Verificar si la fecha es un día excluido
    const isExcludedDay = excludedDaysMap.has(formattedDate);

    const isScheduledDay = configSchedule[`${current.format('dd')}`]?.first;

    return (
      isPastOrFuture || // Deshabilita días pasados o fuera del rango permitido
      feriadosMap.has(`${current.month() + 1}-${current.date()}`) || // Deshabilita feriados
      !isScheduledDay || // Deshabilita días no programados
      isExcludedDay // Deshabilita días excluidos
    );
  };

  const feriadosMap = useMemo(() => {
    const map = new Map();

    feriados.forEach((feriado) => {
      const key = `${feriado.mes}-${feriado.dia}`;
      map.set(key, true);
    });
    return map;
  }, [feriados]);

  const excludedDaysMap = useMemo(() => {
    const map = new Map();
    data?.scheduleConfig?.excludeDays
      .filter((day) => !dayjs(day).isBefore(dayjs().startOf('day'))) // Filtra solo días futuros o presentes
      .forEach((day) => {
        const formattedDay = dayjs(day)
          .tz(tenantTimezone)
          .startOf('day')
          .toISOString(); // Convertir al mismo formato de comparación
        map.set(formattedDay, true);
      });
    return map;
  }, [data?.scheduleConfig?.excludeDays]);

  const generateSlots = async (sizeSlot, turns) => {
    let generate = [];
    let minutesOfHour = 60;

    let configSchedule = {
      excludeDays: [],
      lu: {},
      ma: {},
      mi: {},
      ju: {},
      vi: {},
      sá: {},
      do: {},
    };

    let data =
      turnType !== 'medico'
        ? await studies?.find((studie) => {
            if (studie.id === turnUser) return studie;
          })
        : await users.find((user) => user._id === turnUser);

    if (
      dayjs(date).tz(tenantTimezone).diff(dayjs(), 'days') >
      (data?.scheduleConfig?.quantityDaysPortal || 15)
    ) {
      return setDate(dayjs().tz(tenantTimezone).format('YYYY-MM-DDTHH:mm:ss'));
    }

    if (turns?.scheduleConfig && turns?.shifts?.length > 0) {
      await turns?.scheduleConfig?.hours.map((h, index) => {
        h.days.map((e) => {
          configSchedule[e] =
            Object.entries(configSchedule[e]).length === 0
              ? {
                  first: [
                    dayjs(turns.scheduleConfig?.hours[index]?.start),
                    dayjs(turns.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : Object.entries(configSchedule[e]).length === 1
              ? {
                  ...configSchedule[e],
                  second: [
                    dayjs(turns.scheduleConfig?.hours[index]?.start),
                    dayjs(turns.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : { ...configSchedule[e] };
        });
      });

      let configScheduleUpdating = {
        excludeDays: [],
        lu: {},
        ma: {},
        mi: {},
        ju: {},
        vi: {},
        sá: {},
        do: {},
      };

      await data.scheduleConfig?.hours.map((h, index) => {
        h.days.map((e) => {
          configScheduleUpdating[e] =
            Object.entries(configScheduleUpdating[e]).length === 0
              ? {
                  first: [
                    dayjs(data.scheduleConfig?.hours[index]?.start),
                    dayjs(data.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : Object.entries(configScheduleUpdating[e]).length === 1
              ? {
                  ...configScheduleUpdating[e],
                  second: [
                    dayjs(data.scheduleConfig?.hours[index]?.start),
                    dayjs(data.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : { ...configScheduleUpdating[e] };
        });
      });
      setConfigSchedule(configScheduleUpdating);
    } else {
      await data.scheduleConfig?.hours.map((h, index) => {
        h.days.map((e) => {
          configSchedule[e] =
            Object.entries(configSchedule[e]).length === 0
              ? {
                  first: [
                    dayjs(data.scheduleConfig?.hours[index]?.start),
                    dayjs(data.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : Object.entries(configSchedule[e]).length === 1
              ? {
                  ...configSchedule[e],
                  second: [
                    dayjs(data.scheduleConfig?.hours[index]?.start),
                    dayjs(data.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : { ...configSchedule[e] };
        });
      });
      setConfigSchedule(configSchedule);
    }

    let hours =
      configSchedule[`${dayjs(date).format('dd')}`]?.first !== undefined &&
      configSchedule[`${dayjs(date).format('dd')}`]?.first[0].format('H');

    let minutes =
      configSchedule[`${dayjs(date).format('dd')}`]?.first !== undefined &&
      configSchedule[`${dayjs(date).format('dd')}`]?.first[0].format('mm');

    let hourStart = hours && dayjs(date).startOf('day').add(hours, 'h');

    hourStart = hourStart && hourStart.add(minutes, 'm');

    let res1 =
      hourStart &&
      configSchedule[`${hourStart.format('dd')}`]?.first !== undefined
        ? configSchedule[`${hourStart.format('dd')}`]?.first[1].diff(
            configSchedule[`${hourStart.format('dd')}`]?.first[0],
            'minute'
          ) / minutesOfHour
        : 0;

    let q1 = (minutesOfHour / sizeSlot) * res1;

    let res2 =
      hourStart &&
      configSchedule[`${hourStart.format('dd')}`]?.second !== undefined
        ? configSchedule[`${hourStart.format('dd')}`]?.second[1].diff(
            configSchedule[`${hourStart.format('dd')}`]?.second[0],
            'minute'
          ) / minutesOfHour
        : 0;

    let q2 = (minutesOfHour / sizeSlot) * res2 + (res2 !== 0 ? 1 : 0);

    if (
      !data.scheduleConfig?.excludeDays ||
      data.scheduleConfig?.excludeDays.filter(
        (e) =>
          (
            dayjs(e)
              .tz(tenantTimezone)
              .startOf('day')
              .format('YYYY-MM-DDTHH:mm:ss') ===
            dayjs(date)
              .tz(tenantTimezone)
              .startOf('day')
              .format('YYYY-MM-DDTHH:mm:ss')
          ).length === 0
      )
    ) {
      for (let i = 0; i < q1 + q2; i++) {
        generate[i] = {
          hourStart: hourStart.toISOString(),
        };
        if (i == Math.round(q1)) {
          if (
            configSchedule[`${hourStart.format('dd')}`]?.second !== undefined
          ) {
            let hours =
              configSchedule[`${dayjs(date).format('dd')}`]?.second[0].format(
                'H'
              );

            let minutes =
              configSchedule[`${dayjs(date).format('dd')}`]?.second[0].format(
                'mm'
              );

            hourStart = dayjs(date).startOf('day').add(hours, 'h');

            hourStart = hourStart.add(minutes, 'm');

            generate[i] = {
              hourStart: hourStart.toISOString(),
              exclude: true,
              second: true,
            };
            continue;
          }
        }

        hourStart = hourStart.add(sizeSlot, 'm');
      }
    } else {
      generate[0] = {
        exclude: true,
      };
    }

    setData(data);
    setSlots(generate);
    setSize(sizeSlot);
  };

  if ((turnUser && !slots) || loading) return <Spin />;

  return turnDetail ? (
    <TurnDetail
      turnDetail={turnDetail}
      doctor={
        turnType === 'medico'
          ? users.find((user) => turnUser === user._id)
          : studies.find((studie) => turnUser === studie.id)
      }
      turnType={turnType}
    />
  ) : (
    <div
      className={window.location.href.includes('portalturnos') ? 'online' : ''}
    >
      <Card
        title={`Turnos ${organization}`}
        extra={
          !window.location.href.includes('portalturnos') && (
            <Tooltip
              title="Solo puede solicitar un maximos de tres turnos. Toda la informacion en cuanto a reprogramación o
        cancelacion de sus turnos sera informada via email, por favor estar
        atento a todas las notificaciones enviadas, ante cualquier duda
        comunicarse con la intitución."
            >
              <InfoCircleOutlined />
            </Tooltip>
          )
        }
      >
        {portalActive && window.location.href.includes('portalturnos') && (
          <div className="online-studies">
            <a href={`https://portal${tenantId}.ampinasoft.com`}>
              Ver mis Estudios
            </a>
          </div>
        )}
        <Row gutter={[20, 24]} style={{ marginBottom: 5 }}>
          <Col sm={2} />

          <Col xs={24} sm={10}>
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Busque por medico o especialidad"
              optionFilterProp="children"
              value={turnUser}
              onChange={(value, options) => {
                const turnType = options?.key ? 'studie' : 'medico';
                setTurnType(turnType);
                setTurnUser(value);
              }}
              allowClear
            >
              {users?.map((user, index) => {
                return (
                  <Select.Option value={user._id}>
                    {user.name} {user.lastname}{' '}
                    {user.medicalSpeciality && `- ${user.medicalSpeciality}`}
                  </Select.Option>
                );
              })}
              {studies?.map((studie, index) => (
                <Select.Option key={index} value={studie.id}>
                  Estudio - {studie.name}
                </Select.Option>
              ))}
            </Select>
            {/* <TreeSelect
              inputMode="none"
              style={{ width: '100%' }}
              placeholder="Busque por medico o especialidad"
              optionFilterProp="children"
              onChange={(value, options) => {
                const hasHyphen = value.includes('-');
                const turnType = hasHyphen ? options[0] : 'medico';
                const turnUser = hasHyphen ? value.split('-')[0] : value;

                setTurnType(turnType);
                setTurnUser(turnUser);
              }}
              value={turnType === 'medico' ? turnUser : turnType}
              treeExpandAction="click"
              treeData={
                studies?.length > 0
                  ? [
                      {
                        value: 'med',
                        title: 'Staff Profesional',
                        selectable: false,
                        children: users?.map((user, index) => {
                          return {
                            value: user._id,
                            title: `${user.name} ${user.lastname} ${
                              user?.medicalSpeciality
                                ? `- ${user?.medicalSpeciality}`
                                : ''
                            }`,
                          };
                        }),
                      },
                      {
                        value: 'est',
                        title: 'Estudios',
                        selectable: false,
                        children: studies?.map((studie, index) => {
                          let services = servicesData?.filter((service) => {
                            if (
                              studie.scheduleConfig.service?.find(
                                (e) => e === service._id
                              )
                            ) {
                              return service;
                            }
                          });
                          return {
                            value: `${studie.id}-${index}`,
                            title: studie.name,
                            selectable: window.location.href.includes(
                              'portalturnos'
                            )
                              ? true
                              : false,
                            children:
                              !window.location.href.includes('portalturnos') &&
                              services?.map((service, i) => {
                                return {
                                  value: `${studie.id}-${i}`,
                                  title: service?.name,
                                };
                              }),
                          };
                        }),
                      },
                    ]
                  : [
                      {
                        value: 'med',
                        title: 'Staff Profesional',
                        selectable: false,
                        children: users?.map((user, index) => {
                          return {
                            value: user._id,
                            title: `${user.name} ${user.lastname} ${
                              user?.medicalSpeciality
                                ? `- ${user?.medicalSpeciality}`
                                : ''
                            }`,
                          };
                        }),
                      },
                    ]
              }
            /> */}
          </Col>

          <Col xs={24} sm={10}>
            <DatePicker
              inputReadOnly
              disabled={!turnUser}
              style={{ width: '100%' }}
              format="DD-MM-YYYY"
              placeholder="Seleccione una fecha.. (*)"
              onChange={(value) => {
                setDate(
                  dayjs(value).tz(tenantTimezone).format('YYYY-MM-DDTHH:mm:ss')
                );
              }}
              disabledDate={disabledDate}
              locale={locale}
              allowClear={false}
              value={dayjs(date).tz(tenantTimezone)}
            />
          </Col>
          <Col sm={2} />
        </Row>
        {turnUser ? (
          <div>
            <Collapse
              style={{ backgroundColor: '#E9E9E9' }}
              className="online-collapse"
            >
              <Collapse.Panel
                header="Horarios de atención habituales"
                collapsible="header"
              >
                <div className="online-hours">
                  {days.map(
                    (day) =>
                      configSchedule[`${day.id}`]?.first && (
                        <div className="online-hours-box">
                          <span>{day.name}</span>
                          <ul>
                            <li>
                              {configSchedule[`${day.id}`]?.first[0]
                                .tz(tenantTimezone)
                                .format('HH mm')}
                              hs a{' '}
                              {configSchedule[`${day.id}`]?.first[1]
                                .tz(tenantTimezone)
                                .format('HH mm')}
                              hs
                            </li>{' '}
                            {configSchedule[`${day.id}`]?.second && (
                              <li>
                                {configSchedule[`${day.id}`]?.second[0]
                                  .tz(tenantTimezone)
                                  .format('HH mm')}
                                hs a{' '}
                                {configSchedule[`${day.id}`]?.second[1]
                                  .tz(tenantTimezone)
                                  .format('HH mm')}
                                hs
                              </li>
                            )}
                          </ul>
                        </div>
                      )
                  )}
                </div>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '8pt',
                    color: 'grey',
                  }}
                >
                  Si ve turnos fuera de los horarios habituales, puede
                  solicitarlos. Se han habilitado turnos extra.
                </p>
              </Collapse.Panel>
            </Collapse>
            {data?.scheduleConfig?.description && (
              <div className="online-description">
                <h4>Información Importante</h4>
                <p>{data?.scheduleConfig?.description}</p>
              </div>
            )}

            <ListTurns
              slots={slots}
              turnId={turn?._id}
              turns={turn?.shifts}
              turnUser={turnUser}
              setReload={setReload}
              size={size}
              turnType={turnType}
              date={date}
              setTurnDetail={setTurnDetail}
              scheduleConfig={data?.scheduleConfig}
            />
          </div>
        ) : (
          <>
            <div className="online-logo">
              <img
                src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/${
                  tenantLogo || 'logo'
                }.png`}
                alt="Logo"
              />
            </div>

            <div className="online-mini-logo">
              <p style={{ fontSize: '9pt' }}>
                En esta pagina solo aparecen los medicos y estudios que tienen
                su turnera web habilitada.
              </p>
              <p style={{ fontSize: '9pt' }}>
                Ante cualquier duda comunicarse con la institución o medico.
                Contacto:{' '}
                <Button type="link">
                  <a
                    href={`https://wa.me/${organizationPhone}?text=Hola%20${organization}`}
                    target="_blank"
                    referrerPolicy="origin"
                  >
                    <WhatsAppOutlined /> {organizationPhone}
                  </a>
                </Button>
              </p>
              <h2>Desarrollado por</h2>
              <a href="https://ampinasoft.com">
                <img src={LogoNombre} /> <h5>Visita Nuestra Web</h5>
              </a>
            </div>
          </>
        )}
      </Card>
    </div>
  );
}
